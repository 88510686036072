/* stylelint-disable declaration-no-important */
@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  white-space: nowrap;
  border-radius: calc(var(--radius-main) * 4 - 4px);
  font-weight: var(--fw-500);
  font-size: 18px;
  line-height: 1;
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-blue-200);
  transition-property: background-color, color, border-color;
  transition-duration: 0.2s;

  &:hover {
    color: var(--color-blue-800) !important;
    background-color: var(--color-white);
  }

  &--user {
    font-size: 16px;
    padding: 9px 16px !important;
    color: var(--color-blue-800);
    background-color: var(--color-white);

    &:hover {
      border-color: transparent;
      color: var(--color-white) !important;
      background-color: var(--color-blue-200);
    }
  }

  &--order {
    font-size: 16px;
    color: var(--color-white);
    padding: 9px 16px;
  }

  &--request {
    font-size: 20px;
    color: var(--color-white);
    margin: 0;
    padding: 18px 64px;
  }

  &--arrow {
    margin: 0 auto;
    padding: 16px 66px 16px 36px;

    &::after {
      content: "";
      position: absolute;
      right: 33px;
      top: 50%;
      transform: translateY(-53%);
      width: 25px;
      height: 25px;
      background-image: var(--arrow);
      background-position: 0 0;
      background-size: contain;
      background-repeat: no-repeat;
      transition-property: filter;
      transition-duration: 0.2s;
      filter: var(--filter-white);
    }

    &:hover {
      &::after {
        filter: var(--filter-blue-800);
      }
    }
  }

  &--open {
    &:hover {
      background-color: var(--color-white);

      &::after {
        filter: var(--filter-blue-800);
      }
    }

    &::after {
      transform: translateY(-53%) rotate(180deg);
    }
  }

  @include media(tablet-wide) {

    &:hover {
      color: var(--color-white) !important;
      background-color: var(--color-blue-200);
    }

    &--user {

      &:hover {
        border-color: transparent;
        color: var(--color-blue-800) !important;
        background-color: var(--color-white);
      }
    }

    &--arrow {
      &:hover {
        background-color: var(--color-white);

        &::after {
          filter: var(--filter-white);
        }
      }
    }
  }

  @include media(mobile-s) {
    margin: 0 auto;
    width: max-content;
    min-width: auto;
  }
}