@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.service {
  padding: rem(65) 0 rem(65);

  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @include media(mobile-m) {
      grid-template-columns: 1fr;
    }

    &__item {
      display: flex;
      height: 100%;
      position: relative;
      border: 2px solid var(--color-blue-400);
      border-radius: calc(var(--radius-main) * 2 - 4px);

      &::before {
        content: "";
        position: absolute;
        right: 35px;
        top: 34px;
        width: 100px;
        height: 100px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        @include media(mobile-l) {
          width: 80px;
          height: 64px;
        }
      }

      p {
        max-width: 233px;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.12rem;
        margin: auto 0 7px;
      }

      h3 {
        font-size: 42px;

        @include media(tablet-wide) {
          font-size: 28px;
        }
      }

      &--planning {
        &::before {
          background-image: url("../images/icons/planning.svg");
        }
      }

      &--procurement {
        &::before {
          background-image: url("../images/icons/procurement.svg");
        }
      }

      &--installation {
        &::before {
          background-image: url("../images/icons/installation.svg");
        }
      }

      &--customization {
        &::before {
          background-image: url("../images/icons/customization.svg");
        }
      }

    }
  }

  &--home {
    .grid__item {
      padding: rem(125) rem(35) rem(20);
      flex-direction: column;

      &::before {
        right: 35px;
        top: 34px;
      }
    }
  }

  &--services {

    .grid__item {
      display: flex;
      padding: rem(40) rem(35) rem(65);
      flex-direction: column-reverse;

      &::before {
        right: 35px;
        bottom: 36px;
        top: auto;
      }

      @include media(tablet) {
        padding: rem(40) rem(35) rem(130);
      }
    }

    background-color: var(--color-blue-100);
  }
}