/* stylelint-disable no-unknown-animations */
@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.opened {
  .nav__inner {
    transform: translateX(0);
  }

  span {
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.2s;

    &::before,
    &::after {
      animation-duration: 0.2s;
      animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      animation-fill-mode: forwards;
    }

    &::before {
      animation-name: toplinecross;
      background-color: var(--color-blue-800);
    }

    &::after {
      animation-name: bottomlinecross;
      background-color: var(--color-blue-800);
    }
  }

  .logo:not(.logo--tablet) {
    display: none;
    filter: invert(97%) sepia(7%) saturate(33%) hue-rotate(97deg) brightness(94%) contrast(93%);
  }

  .logo--tablet {
    filter: invert(97%) sepia(7%) saturate(33%) hue-rotate(97deg) brightness(94%) contrast(93%);
  }
}