@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.article {
  max-width: 1040px;
  padding: 165px 15px 170px;
  margin: 0 auto;

  .product-image {
    max-width: 490px;
    width: 100%;
    height: 392px;
  }

  &__arrow {
    display: inline-block;
    position: relative;
    font-weight: var(--fw-500);
    padding-left: 28px;
    margin-bottom: 18px;
    transition: color .2s;

    &::before {
      content: "";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      width: 18px;
      height: 18px;
      background-image: var(--arrow-right);
      background-repeat: no-repeat;
      filter: var(--filter-blue-800);
      background-size: contain;
      background-position: center;
      transition: filter .2s;
    }

    &:hover {
      color: var(--color-blue-200);

      &::before {
        filter: var(--filter-blue-200);
      }
    }
  }

  img {
    height: 100%;
    object-fit: cover;
  }

  &__inner {
    display: flex;
    gap: 25px;
  }

  h2 {
    font-size: 22px;
    font-weight: var(--fw-500);
    padding: 0;
    margin-bottom: rem(40);
  }

  &__text {
    max-width: 495px;
    width: 100%;

    p {
      font-size: 14px;
      text-transform: uppercase;
      width: 100%;
      letter-spacing: 0.12rem;
      line-height: 1.5;
      color: var(--color-gray-800);
      padding: 0;

      &:not(:last-child) {
        margin-bottom: rem(10);
      }
    }
  }

  h4 {
    font-size: 14px;
    text-transform: uppercase;
    color: var(--color-blue-600);
    display: inline;

    +p {
      display: inline;
    }
  }

  @include media(tablet-wide) {
    padding: 135px 15px;

    &__arrow {
      &:hover {
        color: var(--color-blue-800);

        &::before {
          filter: var(--filter-blue-800);
        }
      }
    }
  }

  @include media(mobile-l) {
    padding: 100px 15px;

    &__inner {
      flex-direction: column;
    }

    .product-image,
    img,
    .text {
      max-width: 100%;
      width: 100%;
    }
  }
}