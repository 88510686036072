@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.grid {
  $root: &;

  display: grid;

  &__item {
    position: relative;
    overflow: hidden;
    padding: rem(34);
    background-color: var(--color-white);
  }

  h3 {
    margin-bottom: 15px;
    font-size: 32px;
  }

  p {
    line-height: 1.4;
  }

  &[data-items="1"] {
    --column: 1;

    grid-template-columns: repeat(var(--column), 1fr 5fr);
    grid-gap: rem(20) rem(15);

    h3 {
      justify-self: start;
      align-self: start;
      grid-column: 2 /3;
    }

    @include media(tablet-wide) {
      grid-template-columns: repeat(var(--column), 1fr 4fr);
    }

    @include media(mobile-l) {
      grid-template-columns: repeat(var(--column), 1fr 6fr);
    }

    @include media(mobile-s) {
      grid-template-columns: repeat(var(--column), 1fr);

      h3 {
        grid-column: 1/2;
      }
    }
  }

  &[data-items="5"] {
    --column: 6;

    grid-template-columns: repeat(var(--column), 1fr);

    #{$root}__item {
      &:nth-child(1) {
        grid-column: span calc(var(--column) / 1.5);
      }

      &:nth-child(2) {
        grid-column: span calc(var(--column) / 3);
      }

      &:nth-child(3) {
        grid-column: span calc(var(--column) / 3);
      }

      &:nth-child(4) {
        grid-column: span calc(var(--column) / 1.5);
      }
    }
  }

  @include media(tablet-wide) {
    &:not([data-items="1"])[data-items] {
      --column: 2;

      .grid__item {
        grid-column: auto;
      }
    }
  }

  @include media(mobile-l) {
    &[data-items="6"] {
      --column: 1;

      grid-template-columns: 1fr;
    }
  }

  @include media(mobile-l) {
    &[data-items] {
      --column: 1;
    }

    &[data-items="5"] {
      --column: 1;

      grid-template-columns: 1fr;
    }
  }
}