@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.testimonials {
  padding: rem(67) 0 rem(55);

  h2 {
    line-height: 1.35;

    +p {
      margin-bottom: rem(73);
    }
  }

  &__item {
    max-width: 495px;
    width: 100%;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 495));
    justify-content: center;
    gap: rem(15) rem(20);

    p {
      font-size: 18px;
      line-height: 1.56;
      font-weight: var(--fw-500);
    }

    h3 {
      font-size: 14px;
      letter-spacing: 1.3;
      text-transform: uppercase;
    }
  }

  @include media(tablet) {
    &__item {
      max-width: 100%;
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }

  @include media(mobile-l) {
    &__grid {
      gap: rem(50);
    }
  }

  img {
    width: 70px;
    height: 70px;
    border-radius: calc(var(--radius-main) * 6 - 4px);
    object-fit: cover;
  }
}