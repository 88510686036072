@font-face {
  src: url("../DMSans-Regular.b8a368e5.ttf") format("woff2");
  font-family: DMSans;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../DMSans-Medium.8321812d.ttf") format("woff2");
  font-family: DMSans;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../DMSans-Bold.32e27e5a.ttf") format("woff2");
  font-family: DMSans;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../Poppins-Regular.ab57e00e.ttf") format("woff2");
  font-family: Poppins;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../Poppins-SemiBold.116fba4e.ttf") format("woff2");
  font-family: Poppins;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../DMSans-Regular.cf136573.woff") format("woff");
  font-family: DMSans;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../DMSans-Medium.92aa3ea1.woff") format("woff");
  font-family: DMSans;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../DMSans-Bold.a9959261.woff") format("woff");
  font-family: DMSans;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../Poppins-Regular.05813ff1.woff") format("woff");
  font-family: Poppins;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../Poppins-SemiBold.665ebd97.woff") format("woff");
  font-family: Poppins;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

:root {
  --content-width: 1216px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "DMSans", sans-serif;
  --font-family-secondary: "Poppins", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --color-white: #fff;
  --color-black: #000;
  --color-blue-100: #f4f3fa;
  --color-blue-200: #25dac5;
  --color-blue-400: #ebeaed;
  --color-blue-600: #a1a1b0;
  --color-blue-700: #737288;
  --color-blue-800: #073e57;
  --color-gray-100: #e3e5e4;
  --color-gray-800: #595959;
  --color-red-800: red;
  --filter-white: invert(96%) sepia(2%) saturate(0%) hue-rotate(184deg) brightness(116%) contrast(100%);
  --filter-blue-200: invert(60%) sepia(98%) saturate(347%) hue-rotate(123deg) brightness(99%) contrast(89%);
  --filter-blue-800: invert(14%) sepia(45%) saturate(2706%) hue-rotate(174deg) brightness(102%) contrast(95%);
  --filter-gray-800: invert(32%) sepia(3%) saturate(0%) hue-rotate(91deg) brightness(103%) contrast(90%);
  --radius-main: 12px;
  --gradient: linear-gradient(90deg, #000000b1 15%, #00000081 40%);
  --arrow: url("data:image/svg+xml,  <svg width=\"21\" height=\"20\" viewBox=\"0 0 21 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">   <path d=\"M17.1004 7.45834L11.6671 12.8917C11.0254 13.5333 9.97539 13.5333 9.33372 12.8917L3.90039 7.45834\" stroke=\"black\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>   </svg>");
  --user: url("data:image/svg+xml,<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">   <path d=\"M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM7.35 18.5C8.66 17.56 10.26 17 12 17C13.74 17 15.34 17.56 16.65 18.5C15.34 19.44 13.74 20 12 20C10.26 20 8.66 19.44 7.35 18.5ZM18.14 17.12C16.45 15.8 14.32 15 12 15C9.68 15 7.55 15.8 5.86 17.12C4.7 15.73 4 13.95 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 13.95 19.3 15.73 18.14 17.12Z\" fill=\"black\" fill-opacity=\"0.6\"/>   <path d=\"M12 6C10.07 6 8.5 7.57 8.5 9.5C8.5 11.43 10.07 13 12 13C13.93 13 15.5 11.43 15.5 9.5C15.5 7.57 13.93 6 12 6ZM12 11C11.17 11 10.5 10.33 10.5 9.5C10.5 8.67 11.17 8 12 8C12.83 8 13.5 8.67 13.5 9.5C13.5 10.33 12.83 11 12 11Z\" fill=\"black\" fill-opacity=\"0.6\"/>   </svg>");
  --mail: url("data:image/svg+xml,<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">   <path d=\"M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V8L12 13L20 8V18ZM12 11L4 6H20L12 11Z\" fill=\"black\" fill-opacity=\"0.6\"/>   </svg>");
  --arrow-right: url("data:image/svg+xml,  <svg width=\"14\" height=\"12\" viewBox=\"0 0 14 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">   <path d=\"M0.414531 6.39804L5.47703 11.4605C5.58258 11.5661 5.72573 11.6254 5.875 11.6254C6.02427 11.6254 6.16742 11.5661 6.27297 11.4605C6.37852 11.355 6.43781 11.2118 6.43781 11.0626C6.43781 10.9133 6.37852 10.7701 6.27297 10.6646L2.17023 6.56257H13.1875C13.3367 6.56257 13.4798 6.50331 13.5852 6.39782C13.6907 6.29233 13.75 6.14925 13.75 6.00007C13.75 5.85088 13.6907 5.70781 13.5852 5.60232C13.4798 5.49683 13.3367 5.43757 13.1875 5.43757H2.17023L6.27297 1.33554C6.37852 1.22999 6.43781 1.08684 6.43781 0.937569C6.43781 0.788301 6.37852 0.645148 6.27297 0.5396C6.16742 0.434052 6.02427 0.374756 5.875 0.374756C5.72573 0.374756 5.58258 0.434052 5.47703 0.5396L0.414531 5.6021C0.362232 5.65434 0.320742 5.71638 0.292435 5.78466C0.264127 5.85295 0.249558 5.92615 0.249558 6.00007C0.249558 6.07399 0.264127 6.14719 0.292435 6.21547C0.320742 6.28376 0.362232 6.3458 0.414531 6.39804Z\" fill=\"black\"/>   </svg>");
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  font-family: var(--font-family-primary);
  font-weight: var(--fw-700);
  letter-spacing: 0;
  color: var(--color-blue-800);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.4;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.background {
  background-color: var(--color-white);
}

.page.open {
  overflow: hidden;
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  background: var(--gradient);
  opacity: .85;
  position: absolute;
  inset: 0;
}

.background {
  background-color: var(--color-white);
}

.background--dark {
  background-color: var(--color-blue-100);
}

.logo {
  max-width: 214px;
  width: 100%;
  transition: filter .3s;
}

.logo img {
  max-width: 214px;
  width: 100%;
}

@media only screen and (max-width: 1180px) {
  .logo {
    order: 1;
  }
}

.show {
  display: none;
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--fw-700);
  color: var(--color-dark-400);
  line-height: 1.05;
  overflow: hidden;
}

h1 {
  letter-spacing: -.02rem;
  font-size: 58px;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 34px;
  }
}

h2, .title {
  text-align: left;
  color: inherit;
  padding-left: 105px;
  font-size: 36px;
  line-height: 1.3;
}

@media only screen and (max-width: 992px) {
  h2, .title {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 475px) {
  h2, .title {
    padding-left: 0;
  }
}

h2 + p, .title + p {
  letter-spacing: .03rem;
  margin-bottom: 1.875rem;
  padding-left: 105px;
  font-size: 20px;
  line-height: 1.2;
}

@media only screen and (max-width: 992px) {
  h2 + p, .title + p {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 475px) {
  h2 + p, .title + p {
    padding-left: 0;
  }
}

h3, .title--sec {
  font-size: 32px;
}

p {
  color: var(--color-blue-600);
  font-size: 20px;
  line-height: 1.5;
}

.header {
  z-index: 10;
  color: var(--color-white);
  font-weight: var(--fw-500);
  width: 100%;
  background-color: #0000;
  margin: 0 auto;
  padding: 10px;
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header > .container {
  max-width: 1040px;
}

.header.hide {
  transform: translateY(calc(-100% - 30px));
}

.header--secondary {
  color: var(--color-white);
  background-color: var(--color-blue-200);
}

.header .logo {
  order: 2;
}

.header .logo--tablet {
  display: none;
}

@media only screen and (max-width: 992px) {
  .header .logo--tablet {
    margin-right: auto;
    display: block;
  }
}

.scroll {
  color: var(--color-white);
  background-color: var(--color-blue-200);
}

.nav {
  justify-content: space-around;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .header .nav__inner {
    z-index: -1;
    color: var(--color-blue-800);
    padding-top: calc(var(--header-height) * 1.5);
    width: 100%;
    height: calc(var(--vh));
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  order: 1;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .nav__list {
    order: 2;
  }
}

@media only screen and (max-width: 1280px) {
  .header .nav__list {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .header .nav__list {
    z-index: -1;
    flex-direction: column;
  }
}

.nav__item:not(:last-of-type) {
  margin-right: 40px;
}

@media only screen and (max-width: 992px) {
  .header .nav__item {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .header .nav__item {
    margin-bottom: 10px;
  }
}

.nav__link {
  letter-spacing: .035em;
  white-space: nowrap;
  text-transform: capitalize;
  color: var(--color-dark-450);
  background-color: #0000;
  border-bottom: 1px solid #0000;
  padding: 10px 0;
  font-size: 16px;
  transition: color .2s;
  display: inline-block;
}

.nav .true {
  border-color: var(--color-white);
}

@media only screen and (max-width: 992px) {
  .nav .true {
    border-color: #0000;
  }
}

.nav__btns {
  order: 3;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .nav__btns {
    order: 3;
  }
}

.nav__btns .btn--user {
  margin-right: 5px;
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 475px) {
  .nav__btns {
    flex-direction: column;
    gap: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .nav .nav__list {
    margin: 0 auto;
  }

  .nav .nav__item {
    margin-right: 0;
  }

  .nav--footer {
    flex-direction: column;
  }

  .nav--footer .logo, .nav--footer .nav__list {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 992px) and (max-width: 576px) {
  .nav--footer .nav__list {
    flex-direction: column;
  }
}

@media only screen and (max-width: 992px) {
  .nav--footer .nav__inner {
    flex-direction: column;
    align-items: center;
  }
}

[class].btn {
  white-space: nowrap;
  border-radius: calc(var(--radius-main) * 4 - 4px);
  font-weight: var(--fw-500);
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-blue-200);
  border: 1px solid #0000;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 1;
  transition-property: background-color, color, border-color;
  transition-duration: .2s;
  display: flex;
  position: relative;
}

[class].btn:hover {
  background-color: var(--color-white);
  color: var(--color-blue-800) !important;
}

[class].btn--user {
  color: var(--color-blue-800);
  background-color: var(--color-white);
  font-size: 16px;
  padding: 9px 16px !important;
}

[class].btn--user:hover {
  background-color: var(--color-blue-200);
  border-color: #0000;
  color: var(--color-white) !important;
}

[class].btn--order {
  color: var(--color-white);
  padding: 9px 16px;
  font-size: 16px;
}

[class].btn--request {
  color: var(--color-white);
  margin: 0;
  padding: 18px 64px;
  font-size: 20px;
}

[class].btn--arrow {
  margin: 0 auto;
  padding: 16px 66px 16px 36px;
}

[class].btn--arrow:after {
  content: "";
  width: 25px;
  height: 25px;
  background-image: var(--arrow);
  filter: var(--filter-white);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  transition-property: filter;
  transition-duration: .2s;
  position: absolute;
  top: 50%;
  right: 33px;
  transform: translateY(-53%);
}

[class].btn--arrow:hover:after {
  filter: var(--filter-blue-800);
}

[class].btn--open:hover {
  background-color: var(--color-white);
}

[class].btn--open:hover:after {
  filter: var(--filter-blue-800);
}

[class].btn--open:after {
  transform: translateY(-53%)rotate(180deg);
}

@media only screen and (max-width: 1180px) {
  [class].btn:hover {
    background-color: var(--color-blue-200);
    color: var(--color-white) !important;
  }

  [class].btn--user:hover {
    background-color: var(--color-white);
    border-color: #0000;
    color: var(--color-blue-800) !important;
  }

  [class].btn--arrow:hover {
    background-color: var(--color-white);
  }

  [class].btn--arrow:hover:after {
    filter: var(--filter-white);
  }
}

@media only screen and (max-width: 475px) {
  [class].btn {
    width: max-content;
    min-width: auto;
    margin: 0 auto;
  }
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-white);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened .opened span .burger:before, .closed.opened .opened span .burger:after {
  background-color: var(--color-blue-800);
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  background-color: var(--color-blue-800);
  animation-name: toplinecross;
}

.opened span:after {
  background-color: var(--color-blue-800);
  animation-name: bottomlinecross;
}

.opened .logo:not(.logo--tablet) {
  filter: invert(97%) sepia(7%) saturate(33%) hue-rotate(97deg) brightness(94%) contrast(93%);
  display: none;
}

.opened .logo--tablet {
  filter: invert(97%) sepia(7%) saturate(33%) hue-rotate(97deg) brightness(94%) contrast(93%);
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy {
  padding: 4.875rem 0;
}

.policy p {
  color: var(--color-dark-900);
  margin-bottom: 24px;
  padding: 0;
  line-height: 1.55 !important;
}

.policy > * {
  font-family: var(--font-family-secondary);
}

.policy__content {
  max-width: 1000px;
  margin: 0 auto 3rem;
}

.policy h2 {
  margin-bottom: 24px;
  padding: 0;
  font-size: 30px;
}

.policy ul {
  margin-bottom: 24px;
  padding-left: 15px;
}

.policy li {
  padding-left: 5px;
  font-size: 20px;
  line-height: 1.35;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
  margin-right: 5px;
}

.policy p {
  font-size: 20px;
  line-height: 1.2;
}

.policy a {
  color: var(--color-dark-900);
  text-decoration: underline;
}

.policy b, .policy strong {
  font-weight: var(--fw-600);
}

@media only screen and (max-width: 768px) {
  .policy {
    padding: 40px 0;
  }

  .policy h1 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.footer {
  color: var(--color-white);
  background-color: var(--color-blue-800);
  font-weight: var(--fw-500);
  padding: 3.25rem 0 3.3125rem;
  font-size: 18px;
}

.footer__inner {
  justify-content: space-between;
  margin-bottom: 1.25rem;
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .footer__inner {
    flex-direction: column-reverse;
    align-items: center;
    gap: 1.875rem;
  }
}

.footer .nav__inner {
  justify-content: flex-end;
}

@media only screen and (max-width: 992px) {
  .footer .nav__inner {
    margin-bottom: .9375rem;
  }
}

.footer .nav__list {
  margin: 0;
}

@media only screen and (max-width: 992px) {
  .footer .nav__list {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}

.footer .nav__item:not(:last-child) {
  margin-right: 1.9375rem;
}

@media only screen and (max-width: 1180px) {
  .footer .nav__item:not(:last-child) {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .footer .nav__item:not(:last-child) {
    margin-right: 0;
  }
}

.footer .nav__link {
  color: var(--color-white);
  padding: 0;
  font-size: 18px;
}

@media only screen and (max-width: 1180px) {
  .footer .nav__link {
    padding: 10px 0;
  }
}

@media only screen and (max-width: 768px) {
  .footer .nav__link {
    padding: 0;
  }
}

.footer .copyright {
  text-align: center;
  padding: 0 10px;
  font-size: 16px;
}

.footer button.formsapp-popup-button, .footer .btn {
  padding: 10px 16px !important;
}

.article {
  max-width: 1040px;
  margin: 0 auto;
  padding: 165px 15px 170px;
}

.article .product-image {
  max-width: 490px;
  width: 100%;
  height: 392px;
}

.article__arrow {
  font-weight: var(--fw-500);
  margin-bottom: 18px;
  padding-left: 28px;
  transition: color .2s;
  display: inline-block;
  position: relative;
}

.article__arrow:before {
  content: "";
  width: 18px;
  height: 18px;
  background-image: var(--arrow-right);
  filter: var(--filter-blue-800);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: filter .2s;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.article__arrow:hover {
  color: var(--color-blue-200);
}

.article__arrow:hover:before {
  filter: var(--filter-blue-200);
}

.article img {
  height: 100%;
  object-fit: cover;
}

.article__inner {
  gap: 25px;
  display: flex;
}

.article h2 {
  font-size: 22px;
  font-weight: var(--fw-500);
  margin-bottom: 2.5rem;
  padding: 0;
}

.article__text {
  max-width: 495px;
  width: 100%;
}

.article__text p {
  text-transform: uppercase;
  width: 100%;
  letter-spacing: .12rem;
  color: var(--color-gray-800);
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
}

.article__text p:not(:last-child) {
  margin-bottom: .625rem;
}

.article h4 {
  text-transform: uppercase;
  color: var(--color-blue-600);
  font-size: 14px;
  display: inline;
}

.article h4 + p {
  display: inline;
}

@media only screen and (max-width: 1180px) {
  .article {
    padding: 135px 15px;
  }

  .article__arrow:hover {
    color: var(--color-blue-800);
  }

  .article__arrow:hover:before {
    filter: var(--filter-blue-800);
  }
}

@media only screen and (max-width: 768px) {
  .article {
    padding: 100px 15px;
  }

  .article__inner {
    flex-direction: column;
  }

  .article .product-image, .article img, .article .text {
    max-width: 100%;
    width: 100%;
  }
}

.contact {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 4.25rem 0 4rem;
  display: flex;
}

.contact .container {
  z-index: 1;
}

.contact .title {
  color: var(--color-white);
  margin-bottom: 0;
  line-height: 1.25;
}

.contact .title + p {
  max-width: 800px;
  color: var(--color-white);
  opacity: .6;
  margin-bottom: .875rem;
}

.contact__wrapper {
  border-radius: calc(var(--radius-main)  - 4px);
  width: 100%;
  max-width: 1010px;
  background-color: #e1e0ddf2;
  flex-direction: column;
  margin: 0 auto;
  padding: 3.875rem 3.75rem 5.8125rem;
  font-size: 14px;
  display: flex;
}

.contact a {
  color: var(--color-gray-800);
  align-self: flex-end;
  margin-bottom: 3.125rem;
  padding-left: 2.8125rem;
  transition: filter .2s;
  display: inline-block;
  position: relative;
}

.contact a:before {
  content: "";
  background-image: var(--mail);
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.contact a:hover {
  filter: invert(97%) sepia(7%) saturate(33%) hue-rotate(97deg) brightness(94%) contrast(93%);
  color: var(--color-black);
}

.contact__form {
  width: 100%;
}

.contact label {
  margin-bottom: 24px;
  font-size: 16px;
  display: block;
  position: relative;
}

.contact label:last-of-type {
  margin-bottom: 48px;
}

.contact label:nth-child(1):before, .contact label:nth-child(2):before {
  content: "";
  background: var(--user) no-repeat;
  width: 24px;
  height: 24px;
  background-position: 0;
  position: absolute;
  top: 18px;
  left: 20px;
}

.contact label:nth-child(2):before {
  background: var(--mail) no-repeat;
}

.contact input {
  border-radius: var(--radius-main);
  width: 100%;
  color: var(--color-gray-800);
  background-color: var(--color-white);
  border-color: #0000;
  padding: 18px;
}

.contact input::placeholder {
  transition: color .2s, filter .3s;
}

.contact input:hover::placeholder {
  filter: invert(97%) sepia(7%) saturate(33%) hue-rotate(97deg) brightness(94%) contrast(93%);
  color: var(--color-black);
}

.contact input:focus::placeholder {
  color: #0000;
  background: none;
}

.contact input:active::placeholder {
  color: #0000;
  background: var(--user) no-repeat;
  background: none;
}

.contact input[type="text"], .contact input[type="email"] {
  padding-left: 55px;
}

.contact .btn {
  padding: 17px 80px;
}

@media only screen and (max-width: 1180px) {
  .contact input:hover::placeholder, .contact a:hover {
    color: var(--color-gray-800);
  }
}

@media only screen and (max-width: 768px) {
  .contact__wrapper {
    padding: 40px;
  }

  .contact .btn {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .contact h2 {
    text-align: center;
    margin: 0 auto 40px;
  }

  .contact__wrapper {
    padding: 40px 20px;
  }

  .contact__inner {
    flex-direction: column;
    align-items: center;
  }

  .contact__form {
    max-width: none;
    margin-bottom: 40px;
    margin-right: 0;
  }
}

.grid {
  display: grid;
}

.grid__item {
  background-color: var(--color-white);
  padding: 2.125rem;
  position: relative;
  overflow: hidden;
}

.grid h3 {
  margin-bottom: 15px;
  font-size: 32px;
}

.grid p {
  line-height: 1.4;
}

.grid[data-items="1"] {
  --column: 1;
  grid-template-columns: repeat(var(--column), 1fr 5fr);
  grid-gap: 1.25rem .9375rem;
}

.grid[data-items="1"] h3 {
  grid-column: 2 / 3;
  place-self: start;
}

@media only screen and (max-width: 1180px) {
  .grid[data-items="1"] {
    grid-template-columns: repeat(var(--column), 1fr 4fr);
  }
}

@media only screen and (max-width: 768px) {
  .grid[data-items="1"] {
    grid-template-columns: repeat(var(--column), 1fr 6fr);
  }
}

@media only screen and (max-width: 475px) {
  .grid[data-items="1"] {
    grid-template-columns: repeat(var(--column), 1fr);
  }

  .grid[data-items="1"] h3 {
    grid-column: 1 / 2;
  }
}

.grid[data-items="5"] {
  --column: 6;
  grid-template-columns: repeat(var(--column), 1fr);
}

.grid[data-items="5"] .grid__item:nth-child(1) {
  grid-column: span calc(var(--column) / 1.5);
}

.grid[data-items="5"] .grid__item:nth-child(2), .grid[data-items="5"] .grid__item:nth-child(3) {
  grid-column: span calc(var(--column) / 3);
}

.grid[data-items="5"] .grid__item:nth-child(4) {
  grid-column: span calc(var(--column) / 1.5);
}

@media only screen and (max-width: 1180px) {
  .grid:not([data-items="1"])[data-items] {
    --column: 2;
  }

  .grid:not([data-items="1"])[data-items] .grid__item {
    grid-column: auto;
  }
}

@media only screen and (max-width: 768px) {
  .grid[data-items="6"] {
    --column: 1;
    grid-template-columns: 1fr;
  }

  .grid[data-items] {
    --column: 1;
  }

  .grid[data-items="5"] {
    --column: 1;
    grid-template-columns: 1fr;
  }
}

.product a {
  height: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.product h3 {
  height: 100%;
  color: inherit;
  margin-bottom: 17px;
  font-size: 22px;
  line-height: 1.2;
}

.product p {
  max-width: 233px;
  text-transform: uppercase;
  letter-spacing: .1rem;
  margin-bottom: .625rem;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
}

.product span {
  align-self: flex-end;
  transition: color .2s;
  display: inline-block;
}

.product img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: .9375rem;
}

.product--listing {
  max-width: 392px;
  width: 100%;
}

@media only screen and (max-width: 1280px) {
  .product--listing {
    max-width: 100%;
  }
}

.product--listing img {
  max-width: 392px;
  width: 100%;
  height: 392px;
  margin-bottom: .9375rem;
}

@media only screen and (max-width: 1280px) {
  .product--listing img {
    max-width: 100%;
  }
}

.product:hover span, .product:focus span {
  color: var(--color-blue-600);
}

@media only screen and (max-width: 1180px) {
  .product:hover span, .product:focus span {
    color: var(--color-blue-800);
  }
}

.service {
  padding: 4.0625rem 0;
}

.service .grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

@media only screen and (max-width: 576px) {
  .service .grid {
    grid-template-columns: 1fr;
  }
}

.service .grid__item {
  height: 100%;
  border: 2px solid var(--color-blue-400);
  border-radius: calc(var(--radius-main) * 2 - 4px);
  display: flex;
  position: relative;
}

.service .grid__item:before {
  content: "";
  width: 100px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 34px;
  right: 35px;
}

@media only screen and (max-width: 768px) {
  .service .grid__item:before {
    width: 80px;
    height: 64px;
  }
}

.service .grid__item p {
  max-width: 233px;
  text-transform: uppercase;
  letter-spacing: .12rem;
  margin: auto 0 7px;
  font-size: 14px;
  line-height: 1.5;
}

.service .grid__item h3 {
  font-size: 42px;
}

@media only screen and (max-width: 1180px) {
  .service .grid__item h3 {
    font-size: 28px;
  }
}

.service .grid__item--planning:before {
  background-image: url("../images/icons/planning.svg");
}

.service .grid__item--procurement:before {
  background-image: url("../images/icons/procurement.svg");
}

.service .grid__item--installation:before {
  background-image: url("../images/icons/installation.svg");
}

.service .grid__item--customization:before {
  background-image: url("../images/icons/customization.svg");
}

.service--home .grid__item {
  flex-direction: column;
  padding: 7.8125rem 2.1875rem 1.25rem;
}

.service--home .grid__item:before {
  top: 34px;
  right: 35px;
}

.service--services {
  background-color: var(--color-blue-100);
}

.service--services .grid__item {
  flex-direction: column-reverse;
  padding: 2.5rem 2.1875rem 4.0625rem;
  display: flex;
}

.service--services .grid__item:before {
  top: auto;
  bottom: 36px;
  right: 35px;
}

@media only screen and (max-width: 992px) {
  .service--services .grid__item {
    padding: 2.5rem 2.1875rem 8.125rem;
  }
}

.hero {
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.hero__inner {
  min-height: 584px;
  flex-direction: column;
  justify-content: center;
  padding-inline: 104px 30px;
  display: flex;
  overflow: hidden;
}

.hero__content {
  max-width: 600px;
  z-index: 1;
  height: 100%;
  position: relative;
}

.hero__content > * {
  color: var(--color-white);
}

.hero__content h1 {
  margin-bottom: 20px;
  line-height: 1.2;
}

.hero__content p {
  font-weight: var(--fw-500);
  margin-bottom: 65px;
  font-size: 22px;
}

.hero__content .btn {
  max-width: 251px;
}

.hero__content--simple p {
  margin-bottom: 0;
}

.hero--small {
  min-height: auto;
  max-height: 477px;
}

.hero--small p {
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  .hero {
    min-height: 700px;
    padding-top: 0;
  }

  .hero__content {
    max-width: 90%;
    padding: 20px;
  }

  .hero__inner {
    min-height: 300px;
    padding: 0 30px;
  }
}

@media only screen and (max-width: 768px) {
  .hero {
    min-height: 300px;
    padding: 40px 0;
  }

  .hero__content {
    max-width: 100%;
    padding: 20px;
  }

  .hero__inner {
    min-height: 300px;
    padding: 0;
  }
}

.offer .product-image {
  position: relative;
}

.offer .product-image:before {
  content: "";
  background-color: var(--color-red-800);
  width: 145px;
  height: 45px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.offer .product-image:after {
  content: "Special Dials";
  font-size: 22px;
  font-weight: var(--fw-500);
  color: var(--color-white);
  position: absolute;
  top: 22px;
  right: 24px;
}

.categories {
  background-color: var(--color-white);
  padding: 4.0625rem 0;
}

.categories h2 {
  margin-bottom: 0;
  line-height: 1.3;
}

.categories h2 + p {
  letter-spacing: .03rem;
  margin-bottom: 3.75rem;
  line-height: 1.08;
}

.categories--portfolio {
  padding: 4.0625rem 0 0;
}

.categories--portfolio h2 + p {
  letter-spacing: .03rem;
  margin-bottom: 3.75rem;
  line-height: 1;
}

.categories__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 35px 20px;
  display: grid;
}

.categories__list--portfolio {
  grid-template-rows: 80px 80px 1fr 80px 1fr 80px;
  gap: 0 20px;
}

.categories__list--portfolio .categories__item:nth-child(2) {
  grid-area: 2 / 2 / 3 / 3;
}

.categories__list--portfolio .categories__item:nth-child(3) {
  grid-area: 3 / 3 / 5 / 4;
}

.categories__list--portfolio .categories__item:nth-child(4) {
  grid-area: 5 / 1 / 6 / 2;
}

.categories__list--portfolio .categories__item:nth-child(5) {
  grid-area: 4 / 2 / 5 / 3;
}

@media only screen and (max-width: 992px) {
  .categories {
    padding: 4.0625rem 0 5.625rem;
  }

  .categories__list {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  .categories__list img {
    aspect-ratio: 16 / 7;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  .categories__item {
    width: 100%;
  }

  .categories__list--portfolio {
    grid-gap: 1.875rem;
    grid-template-rows: 1fr;
  }

  .categories--portfolio .categories__item:nth-child(2), .categories--portfolio .categories__item:nth-child(3), .categories--portfolio .categories__item:nth-child(4) {
    grid-area: auto;
  }

  .categories--portfolio .categories__item:nth-child(5) {
    grid-area: auto / span 2;
  }

  .categories img {
    max-width: 100%;
    width: 100%;
  }

  .categories .category:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .categories .category img {
    aspect-ratio: 16 / 7;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .categories__list {
    grid-gap: 3.125rem;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .categories__list img {
    aspect-ratio: 16 / 7;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  .categories .categories__item:nth-child(5) {
    grid-area: auto;
  }
}

.category {
  max-width: 392px;
}

@media only screen and (max-width: 992px) {
  .category {
    max-width: 100%;
  }
}

.category img {
  max-width: 392px;
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-bottom: 1.25rem;
}

.category--portfolio img {
  height: 470px;
}

.category__content {
  max-width: 380px;
}

.category h3 {
  font-size: 22px;
  font-weight: var(--fw-500);
  line-height: 1.45;
  transition: color .2s;
}

.category p {
  text-transform: uppercase;
  letter-spacing: .12rem;
  font-size: 14px;
  line-height: 1.85;
}

@media only screen and (max-width: 1280px) {
  .category--portfolio img {
    height: 400px;
  }
}

@media only screen and (max-width: 768px) {
  .category__content {
    max-width: 100%;
  }
}

.portfolio {
  background-color: var(--color-blue-100);
  padding: 4.375rem 0 3.75rem;
}

.portfolio h2 + p {
  margin-bottom: 3.75rem;
}

.portfolio__grid {
  grid-template-columns: repeat(3, minmax(300px, 395px));
  justify-content: center;
  gap: 4.0625rem 1.25rem;
  display: grid;
}

@media only screen and (max-width: 992px) {
  .portfolio .portfolio__grid {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .portfolio--home .portfolio__grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .portfolio--home .portfolio__item:nth-child(3) {
    grid-column: span 2;
  }

  .portfolio--home .portfolio__item:nth-child(3) img {
    aspect-ratio: 14 / 7;
  }
}

@media only screen and (max-width: 768px) {
  .portfolio__item img {
    aspect-ratio: 14 / 7;
  }

  .portfolio .portfolio__grid {
    grid-template-columns: 1fr;
  }

  .portfolio .portfolio__item:nth-child(3) {
    grid-column: span 1;
  }
}

@media only screen and (max-width: 475px) {
  .portfolio .portfolio__grid {
    grid-template-columns: 1fr;
  }
}

.portfolio--listings {
  padding: 4.1875rem 0 4.25rem;
}

.portfolio--listings .portfolio__grid {
  gap: 2.1875rem 1.25rem;
}

.portfolio--listings h2 + p {
  margin-bottom: 5rem;
}

.portfolio--listings .portfolio__grid {
  margin-bottom: 2.1875rem;
}

.portfolio--listings .portfolio__grid span {
  font-size: 18px;
}

@media only screen and (max-width: 992px) {
  .portfolio .portfolio__item:last-child {
    grid-column: span 2;
  }
}

@media only screen and (max-width: 768px) {
  .portfolio .portfolio__item:last-child {
    grid-column: auto;
  }
}

.testimonials {
  padding: 4.1875rem 0 3.4375rem;
}

.testimonials h2 {
  line-height: 1.35;
}

.testimonials h2 + p {
  margin-bottom: 4.5625rem;
}

.testimonials__item {
  max-width: 495px;
  width: 100%;
}

.testimonials__grid {
  grid-template-columns: repeat(2, minmax(300px, 495px));
  justify-content: center;
  gap: .9375rem 1.25rem;
  display: grid;
}

.testimonials__grid p {
  font-size: 18px;
  line-height: 1.56;
  font-weight: var(--fw-500);
}

.testimonials__grid h3 {
  letter-spacing: 1.3px;
  text-transform: uppercase;
  font-size: 14px;
}

@media only screen and (max-width: 992px) {
  .testimonials__item {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .testimonials__grid {
    grid-template-columns: 1fr;
    gap: 3.125rem;
  }
}

.testimonials img {
  width: 70px;
  height: 70px;
  border-radius: calc(var(--radius-main) * 6 - 4px);
  object-fit: cover;
}

.gallery {
  position: relative;
}

.gallery h2 {
  max-width: 1225px;
  width: 100%;
  z-index: 1;
  letter-spacing: -.1rem;
  color: var(--color-white);
  padding: 35px 0 35px 112px;
  font-size: 96px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 1280px) {
  .gallery h2 {
    font-size: 76px;
  }
}

@media only screen and (max-width: 992px) {
  .gallery h2 {
    padding: 35px 30px;
    font-size: 56px;
  }
}

@media only screen and (max-width: 768px) {
  .gallery h2 {
    padding: 35px 30px;
    font-size: 46px;
  }
}

@media only screen and (max-width: 576px) {
  .gallery h2 {
    padding: 10px 15px;
  }
}

.gallery h2:before {
  content: "";
  z-index: -1;
  width: 100%;
  background-color: #25dac5cc;
  display: block;
  position: absolute;
  inset: 0;
}

.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery .grid {
  gap: 5px;
}

.gallery .grid__item {
  height: 677px;
  padding: 0;
}

@media only screen and (max-width: 1280px) {
  .gallery .grid__item {
    min-height: 377px;
  }
}

@media only screen and (max-width: 992px) {
  .gallery .grid__item {
    min-height: 277px;
  }
}

@media only screen and (max-width: 576px) {
  .gallery .grid__item {
    min-height: 200px;
  }
}

.solution {
  background-color: var(--color-white);
  padding: 4.75rem 0 3.5625rem;
}

.solution h2 {
  line-height: 1.3;
}

.solution h2 + p {
  color: var(--color-blue-600);
  letter-spacing: .03rem;
  margin-bottom: 3.75rem;
}

.solution p:first-child {
  color: var(--color-blue-600);
}

.solution .container {
  flex-direction: column;
  display: flex;
}

.solution__inner {
  grid-template-columns: minmax(300px, 392px) minmax(400px, 594px);
  align-self: flex-end;
  gap: 25px;
  display: grid;
}

.solution__inner:not(:last-child) {
  margin-bottom: 3.125rem;
}

.solution__inner h3 {
  font-size: 22px;
  font-weight: var(--fw-500);
  margin-bottom: 16px;
  line-height: 1.45;
}

.solution__inner--left {
  align-self: flex-start;
}

.solution__content {
  width: 100%;
  max-width: 800px;
}

.solution__content p, .solution__content h4 {
  text-transform: uppercase;
  letter-spacing: .12rem;
  font-size: 14px;
  display: inline;
}

.solution__content p {
  color: var(--color-blue-700);
  line-height: 1.5;
}

.solution__content h4 {
  color: var(--color-blue-600);
}

.solution__item {
  color: var(--color-blue-600);
  margin-bottom: .9375rem;
  margin-left: 25px;
  line-height: 1;
  list-style-type: disc;
  list-style-position: outside;
}

.solution__picture img {
  object-fit: cover;
}

.solution--help {
  color: var(--color-blue-400);
  background-color: var(--color-white);
}

.solution--help .solution__inner {
  align-items: center;
}

.solution--help .solution__inner:not(:last-child) {
  margin-bottom: 6.5rem;
}

.solution--help h2 {
  margin-bottom: 24px;
}

.solution--help h3 {
  margin-bottom: 13px;
  font-size: 40px;
}

@media only screen and (max-width: 768px) {
  .solution__inner {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .solution__inner img {
    aspect-ratio: 16 / 7;
    width: 100%;
  }

  .solution__picture {
    max-width: 100%;
    width: 100%;
  }

  .solution__inner--reverse .solution__content, .solution__inner--reverse .solution__picture {
    grid-column: auto;
  }
}

@media only screen and (max-width: 992px) {
  .solution .solution__inner:not(:last-child) {
    margin-bottom: 2.5rem;
  }
}

/*# sourceMappingURL=main.css.map */
