@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  padding: rem(52) 0 rem(53);
  color: var(--color-white);
  background-color: var(--color-blue-800);
  font-weight: var(--fw-500);
  font-size: 18px;

  &__inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(20);

    @include media(tablet-wide) {
      flex-direction: column-reverse;
      align-items: center;
      gap: rem(30);
    }
  }

  .nav {
    &__inner {
      justify-content: flex-end;

      @include media(tablet) {
        margin-bottom: rem(15);
      }
    }

    &__list {
      margin: 0;

      @include media(tablet) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
      }
    }

    &__item {
      &:not(:last-child) {
        margin-right: rem(31);

        @include media(tablet-wide) {
          margin-right: 20px;
        }

        @include media(mobile-m) {
          margin-right: 0px;
        }
      }
    }

    &__link {
      color: var(--color-white);
      font-size: 18px;
      padding: 0;

      @include media(tablet-wide) {
        padding: 10px 0;
      }

      @include media(mobile-l) {
        padding: 0;
      }
    }
  }

  .copyright {
    padding: 0 10px;
    text-align: center;
    font-size: 16px;
  }

  button.formsapp-popup-button,
  .btn {
    padding: 10px 16px !important;
  }
}