@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.solution {
  $root: &;
  background-color: var(--color-white);
  padding: rem(76) 0 rem(57);

  h2 {
    line-height: 1.3;

    +p {
      color: var(--color-blue-600);
      margin-bottom: rem(60);
      letter-spacing: 0.03rem;
    }
  }

  p:first-child {
    color: var(--color-blue-600);
  }

  & .container {
    display: flex;
    flex-direction: column;
  }

  &__inner {
    align-self: flex-end;
    display: grid;
    grid-template-columns: minmax(300px, 392px) minmax(400px, 594px);
    gap: 25px;

    &:not(:last-child) {
      margin-bottom: rem(50);
    }

    h3 {
      font-size: 22px;
      font-weight: var(--fw-500);
      line-height: 1.45;
      margin-bottom: 16px;
    }

    &--left {
      align-self: flex-start;
    }
  }

  &__content {
    width: 100%;
    max-width: 800px;

    p,
    h4 {
      font-size: 14px;
      display: inline;
      text-transform: uppercase;
      letter-spacing: 0.12rem;
    }

    p {
      color: var(--color-blue-700);
      line-height: 1.5;
    }

    h4 {
      color: var(--color-blue-600);
    }
  }

  &__item {
    margin-bottom: rem(15);
    list-style-type: disc;
    list-style-position: outside;
    color: var(--color-blue-600);
    line-height: 1;
    margin-left: 25px;
  }

  &__picture {
    img {
      object-fit: cover;
    }
  }

  &--help {
    color: var(--color-blue-400);
    background-color: var(--color-white);

    #{$root}__inner {
      align-items: center;

      &:not(:last-child) {
        margin-bottom: rem(104);

      }
    }

    h2 {
      margin-bottom: 24px;
    }

    h3 {
      margin-bottom: 13px;
      font-size: 40px;
    }
  }

  @include media(mobile-l) {
    &__inner {
      grid-template-columns: 1fr;
      justify-content: center;

      img {
        aspect-ratio: 16 / 7;
        width: 100%;
      }
    }

    &__picture {
      max-width: 100%;
      width: 100%;
    }

    &__inner--reverse &__content,
    &__inner--reverse &__picture {
      grid-column: auto;
    }
  }

  @include media(tablet) {
    #{$root}__inner {

      &:not(:last-child) {
        margin-bottom: rem(40);

      }
    }
  }
}