@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  margin: 0 auto;
  color: var(--color-white);
  font-weight: var(--fw-500);
  padding: 10px;
  width: 100%;
  background-color: transparent;
  transition-property: transform, padding;
  transition-duration: 0.15s;

  &>.container {
    max-width: 1040px;
  }

  &.hide {
    transform: translateY(calc(-100% - 30px));
  }

  &--secondary {
    color: var(--color-white);
    background-color: var(--color-blue-200);
  }

  .logo {
    order: 2;

    &--tablet {
      display: none;

      @include media(tablet) {
        display: block;
        margin-right: auto;
      }
    }
  }
}

.scroll {
  color: var(--color-white);
  background-color: var(--color-blue-200);
}