/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

:root {
  --content-width: 1216px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "DMSans", sans-serif;
  --font-family-secondary: "Poppins", sans-serif;

  // font-weight
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // color
  --color-white: #fff;
  --color-black: #000;

  --color-blue-100: #f4f3fa;
  --color-blue-200: #25dac5;
  --color-blue-400: #ebeaed;
  --color-blue-600: #a1a1b0;
  --color-blue-700: #737288;
  --color-blue-800: #073e57;

  --color-gray-100: #e3e5e4;
  --color-gray-800: #595959;

  --color-red-800: #ff0000;


  //filter
  --filter-white: invert(96%) sepia(2%) saturate(0%) hue-rotate(184deg) brightness(116%) contrast(100%);

  --filter-blue-200: invert(60%) sepia(98%) saturate(347%) hue-rotate(123deg) brightness(99%) contrast(89%);
  --filter-blue-800: invert(14%) sepia(45%) saturate(2706%) hue-rotate(174deg) brightness(102%) contrast(95%);

  --filter-gray-800: invert(32%) sepia(3%) saturate(0%) hue-rotate(91deg) brightness(103%) contrast(90%);

  //  radius
  --radius-main: 12px;

  // shadow

  // gradient
  --gradient: linear-gradient(90deg, rgba(0, 0, 0, 0.695203081232493) 15%, rgba(0, 0, 0, 0.5047268907563025) 40%);

  // icons
  --arrow: url('data:image/svg+xml, \ <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"> \
  <path d="M17.1004 7.45834L11.6671 12.8917C11.0254 13.5333 9.97539 13.5333 9.33372 12.8917L3.90039 7.45834" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/> \
  </svg>');

  --user: url('data:image/svg+xml,\<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> \
  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM7.35 18.5C8.66 17.56 10.26 17 12 17C13.74 17 15.34 17.56 16.65 18.5C15.34 19.44 13.74 20 12 20C10.26 20 8.66 19.44 7.35 18.5ZM18.14 17.12C16.45 15.8 14.32 15 12 15C9.68 15 7.55 15.8 5.86 17.12C4.7 15.73 4 13.95 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 13.95 19.3 15.73 18.14 17.12Z" fill="black" fill-opacity="0.6"/> \
  <path d="M12 6C10.07 6 8.5 7.57 8.5 9.5C8.5 11.43 10.07 13 12 13C13.93 13 15.5 11.43 15.5 9.5C15.5 7.57 13.93 6 12 6ZM12 11C11.17 11 10.5 10.33 10.5 9.5C10.5 8.67 11.17 8 12 8C12.83 8 13.5 8.67 13.5 9.5C13.5 10.33 12.83 11 12 11Z" fill="black" fill-opacity="0.6"/> \
  </svg>');
  --mail: url('data:image/svg+xml,\<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> \
  <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V8L12 13L20 8V18ZM12 11L4 6H20L12 11Z" fill="black" fill-opacity="0.6"/> \
  </svg>');

  --arrow-right: url('data:image/svg+xml, \ <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"> \
  <path d="M0.414531 6.39804L5.47703 11.4605C5.58258 11.5661 5.72573 11.6254 5.875 11.6254C6.02427 11.6254 6.16742 11.5661 6.27297 11.4605C6.37852 11.355 6.43781 11.2118 6.43781 11.0626C6.43781 10.9133 6.37852 10.7701 6.27297 10.6646L2.17023 6.56257H13.1875C13.3367 6.56257 13.4798 6.50331 13.5852 6.39782C13.6907 6.29233 13.75 6.14925 13.75 6.00007C13.75 5.85088 13.6907 5.70781 13.5852 5.60232C13.4798 5.49683 13.3367 5.43757 13.1875 5.43757H2.17023L6.27297 1.33554C6.37852 1.22999 6.43781 1.08684 6.43781 0.937569C6.43781 0.788301 6.37852 0.645148 6.27297 0.5396C6.16742 0.434052 6.02427 0.374756 5.875 0.374756C5.72573 0.374756 5.58258 0.434052 5.47703 0.5396L0.414531 5.6021C0.362232 5.65434 0.320742 5.71638 0.292435 5.78466C0.264127 5.85295 0.249558 5.92615 0.249558 6.00007C0.249558 6.07399 0.264127 6.14719 0.292435 6.21547C0.320742 6.28376 0.362232 6.3458 0.414531 6.39804Z" fill="black"/> \
  </svg>');
}