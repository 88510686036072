@use "./mixins/font-face"as *;


@include font-ttf("DMSans", "../fonts/DMSans-Regular", 400, normal);
@include font-ttf("DMSans", "../fonts/DMSans-Medium", 500, normal);
@include font-ttf("DMSans", "../fonts/DMSans-Bold", 700, normal);
@include font-ttf("Poppins", "../fonts/Poppins-Regular", 400, normal);
@include font-ttf("Poppins", "../fonts/Poppins-SemiBold", 600, normal);
@include font-woff("DMSans", "../fonts/DMSans-Regular", 400, normal);
@include font-woff("DMSans", "../fonts/DMSans-Medium", 500, normal);
@include font-woff("DMSans", "../fonts/DMSans-Bold", 700, normal);
@include font-woff("Poppins", "../fonts/Poppins-Regular", 400, normal);
@include font-woff("Poppins", "../fonts/Poppins-SemiBold", 600, normal);