@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.product {
  a {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-size: 22px;
    height: 100%;
    margin-bottom: 17px;
    line-height: 1.2;
    color: inherit;
  }

  p {
    max-width: 233px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.1rem;
    padding: 0;
    margin-bottom: rem(10);
  }

  span {
    display: inline-block;
    align-self: flex-end;
    transition: color .2s;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: rem(15);
  }

  &--listing {
    max-width: 392px;
    width: 100%;

    @include media(desktop) {
      max-width: 100%;
    }

    img {
      max-width: 392px;
      width: 100%;
      height: 392px;
      margin-bottom: rem(15);

      @include media(desktop) {
        max-width: 100%;
      }
    }
  }

  &:hover,
  &:focus {
    span {
      color: var(--color-blue-600);
    }
  }

  @include media(tablet-wide) {

    &:hover,
    &:focus {
      span {
        color: var(--color-blue-800);
      }
    }
  }
}