@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.gallery {
  position: relative;

  h2 {
    max-width: 1225px;
    width: 100%;
    z-index: 1;
    position: absolute;
    display: block;
    font-size: 96px;
    top: 50%;
    padding: 35px 0 35px 112px;
    letter-spacing: -0.1rem;
    transform: translateY(-50%);
    color: var(--color-white);

    @include media(desktop) {
      font-size: 76px;
    }

    @include media(tablet) {
      font-size: 56px;
      padding: 35px 30px;
    }

    @include media(mobile-l) {
      font-size: 46px;
      padding: 35px 30px;
    }

    @include media(mobile-m) {
      padding: 10px 15px;
    }

    &::before {
      content: "";
      z-index: -1;
      position: absolute;
      inset: 0;
      display: block;
      background-color: rgba(37, 218, 197, 0.8);
      width: 100%;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .grid {
    gap: 5px;

    &__item {
      height: 677px;
      padding: 0;

      @include media(desktop) {
        min-height: 377px;
      }

      @include media(tablet) {
        min-height: 277px;
      }

      @include media(mobile-m) {
        min-height: 200px;
      }
    }
  }
}