@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.categories {
  background-color: var(--color-white);
  padding: rem(65) 0 rem(65);

  h2 {
    margin-bottom: 0;
    line-height: 1.3;

    &+p {
      letter-spacing: 0.03rem;
      line-height: 1.08;
      margin-bottom: rem(60);
    }
  }

  &--portfolio {
    padding: rem(65) 0 rem(0);

    h2 {
      &+p {
        letter-spacing: 0.03rem;
        margin-bottom: rem(60);
        line-height: 1;
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 35px 20px;

    &--portfolio {
      grid-template-rows: 80px 80px 1fr 80px 1fr 80px;
      gap: 0 20px;

      .categories__item {

        &:nth-child(2) {
          grid-column: 2/3;
          grid-row: 2/3;
        }

        &:nth-child(3) {
          grid-column: 3/4;
          grid-row: 3/5;
        }

        &:nth-child(4) {
          grid-column: 1/2;
          grid-row: 5/6;
        }

        &:nth-child(5) {
          grid-column: 2/3;
          grid-row: 4/5;
        }
      }

    }
  }

  @include media(tablet) {
    padding: rem(65) 0 rem(90);

    &__list {
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;

      img {
        aspect-ratio: 16 / 7;
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    &__item {
      width: 100%;
    }

    &__list--portfolio {
      grid-template-rows: repeat(auto-fit, minmax(300px, 1fr));
      grid-template-rows: 1fr;
      grid-gap: rem(30);
    }

    &--portfolio {
      .categories__item {

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          grid-column: auto;
          grid-row: auto;
        }

        &:nth-child(5) {
          grid-column: span 2;
          grid-row: auto;
        }
      }
    }

    img {
      max-width: 100%;
      width: 100%;
    }

    .category {

      &:not(:last-child) {
        margin-bottom: rem(40);
      }

      img {
        aspect-ratio: 16 / 7;
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: 1fr;
      justify-items: center;
      grid-gap: rem(50);

      img {
        aspect-ratio: 16 / 7;
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    .categories__item {

      &:nth-child(5) {
        grid-column: auto;
        grid-row: auto;
      }
    }
  }
}

.category {
  max-width: 392px;

  @include media(tablet) {
    max-width: 100%;
  }

  img {
    max-width: 392px;
    width: 100%;
    height: 250px;
    margin-bottom: rem(20);
    object-fit: cover;
  }

  &--portfolio {
    img {
      height: 470px;
    }
  }

  &__content {
    max-width: 380px;
  }

  h3 {
    font-size: 22px;
    font-weight: var(--fw-500);
    line-height: 1.45;
    transition: color .2s;
  }

  p {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.12rem;
    line-height: 1.85;
  }

  @include media(desktop) {
    &--portfolio {
      img {
        height: 400px;
      }
    }
  }

  @include media(mobile-l) {
    &__content {
      max-width: 100%;
    }
  }
}